import React from "react"
import loadable from '@loadable/component'
import Layout from "../components/layout"
import Seo from "../components/seo"
// import AfricanMap from "../components/ui/AfricanMap"

import '../assets/Scss/templates/about.scss'

const AfricanMap = loadable(() => import('../components/ui/AfricanMap'), {ssr: false})

function About() {
  // const [rendered, setRendered] = useState(false)

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setRendered(true)
  //   }
  //   return () => {
  //     setRendered(false)
  //   }
  // }, [])
  return (
    <Layout>
      <Seo title="About AWRA" />
      {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/jvectormap/2.0.4/jquery-jvectormap.css" type="text/css" media="screen"/> */}
      <div className="container">
        <div className="about">
          <section className="who">
            <h2 className="section-title">Who we are?</h2>
            <div className="section-body">
              <p>
                AWRA is a movement for everyone that believes in gender equity,
                openness, and collaborative partnerships. We work to ensure that
                African women and girls live their best lives without
                limitations and gender-based violence.
              </p>
              <p>
                AWRA is unique because it is a survivor-led initiative founded
                by African women in the diaspora and on the continent. In order
                to achieve sustainable change in our communities, we use our
                collective experiences to create and deliver mentorship
                projects, awareness campaigns, and safe spaces for women and
                girls.
              </p>
              <p>
                AWRA is a space where leaders, grassroots activists, and
                entrepreneurs collaborate with allies, and where impactful
                campaigns are created to massively shift policies and ensure
                that accountability measures are in place.
              </p>
            </div>
          </section>
          <AfricanMap />
        </div>
      </div>
    </Layout>
  )
}

export default About
